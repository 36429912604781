.seccion{
    width: 100vw;
    height: 100vh;
}

/*Primera seccion*/
.img_port_fond{
    width: 100vw;
    height: 100vh;
    background: url("../../../img/Grupo\ 8218@2x.png");
    padding: 0px 10%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: center;
    align-items: center;
}

.cont_s1_text{
    margin-top: -30% !important;
}

.title_1s{
    font-family: Gotham !important;
    font-size: 65px !important;
    font-weight: bold;
    text-align: left;
    color: #fff;
}

.sub_title_1s{
    font-family: Gotham !important;
    font-size: 15px !important;
    font-weight: bold;
    text-align: left;
    color: #22c599;
}

.text{
    font-family: Gotham !important;
    font-size: 13px !important;
    text-align: left;
    color: #fff;
    padding-right: 190px;
}

.btn_ir_form{
    width: 250px;
    height: 40px;
    margin-top: 10px;
    border-radius: 19px !important;
}

.cont_s1_img{
    margin-top: -70% !important;
    margin-left: -5%;
}

.img1{
    width: 45%;
    position: absolute !important;
    justify-content: center !important;
    align-items: center;
}

.wp{
    width: 50px !important;
    position: absolute !important;
    justify-content: right !important;
    align-items: right;
    margin-left: 40%;
    margin-top: 34%;
}

/*Segunda seccion*/
.content_seccion_2{
    width: 100vw !important;
    height: 100vh !important;
    padding-top: 170px !important;
}

.img2{
    width: 100%;
    justify-content: center !important;
    align-items: center;
}

.sub_title_2s{
    width: 200.5px;
    height: 12.5px;
    font-family: Gotham;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #4f5961;
}

.title_2s{
    width: 209px;
    height: 45px;
    font-family: Gotham;
    font-size: 65px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #db5369;
}

.text_s2{
    font-family: Gotham;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #1e2430;
    margin-top: 70px;
    margin-right: 190px;
}

.text_s2_sub{
    margin-bottom: 30px;
}

.text_remarcado{
    width: 136px;
    height: 13px;
    color: #fff;
    padding: 1.5px 5px;
    background-color: #db5369;
}

li{
    list-style-type: none;
}

li::before{
    content: ">";
    margin-right: 5px;
}

.btn_s2{
    width: 230px;
    height: 38px;
    margin-top: 10px;
    border-radius: 18px !important;
    background-color: #db5369 !important;
    border: none !important;
}

.btn_s2:hover{
    background-color: #722e39 !important;
}

/* Tercera seccion */
.seccion_3s_text{
    text-align: center;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    align-items: center !important;
    font-family: Gotham;
    padding-top: 50px;
}

.title_3s{
    font-size: 68px;
    font-weight: bold;
    line-height: 1.23;
    color: #22c599;
}

.sub_title_3s{
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5;
    color: #4f5961;
}

.text_s3{
    margin: 30px 36% 0px 36%;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    color: #1e2430;
}

.sec_icon_s3{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: -200px;
    margin-bottom: 70px;
}

.text_icons_s3{
    margin-top: -30px !important;
    opacity: 0.77;
}

/*Cuarta seccion - Formulario*/
.img_form_fond {
    width: 100vw;
    height: 80vh;
    padding: 3.5% 10%;
    object-fit: contain;
    background-image: url("../../../img/grupo_8214.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
}

.items_s4{
    justify-content: center;
    align-items: center;
}

.input{
    width: 100%;
    height: 100%;
    border-radius: 17px !important;
}

.text_area{
    width: 100% !important;
    height: 100px;
}

.cont_text_area_s4{
    margin-left: -26% !important;
}

.desc_form{
    font-family: Gotham !important;
    font-size: 15px !important;
    line-height: 1.5;
    text-align: right;
    color: #fff;
}

.sub_title{
    font-family: Gotham !important;
    font-size: 15px !important;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin-bottom: -10px;
}

.title{
    font-family: Gotham !important;
    font-size: 65px !important;
    font-weight: bold;
    text-align: center;
    color: #fff;
}

.btn_form{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border-radius: 17px !important;
    background-color: #efb23f !important;
    border: none !important;
}

.btn_form:hover{
    background-color: #c07e33 !important;
}

/*Errores*/

.text_error{
    font-size: 9px;
    font-weight: bold;
    line-height: 1.5;
}

