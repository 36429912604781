.content_footer{
    width: 100vw;
    height: 20vh;
    background-color: #2b2d39;
}

.cont_text_footer{
    width: 200.5px;
    height: 37px;
    font-family: Gotham;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #696d79;
    margin-top: 5% !important;
}