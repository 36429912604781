.cont_navbar{
    margin-top: -12% !important;
}

.cont_img_navbar{
    margin-top: -10px !important;
}

.img_inmoclick{
    width: 160px;
    height: 35px;
    margin-top: -7px;
    margin-right: 3px;
}

.img_signo_mas{
    width: 35px;
    height: 20px;
    margin-top: 5px;
    margin-left: 10px;
}

.img_tokko{
    width: 70px;
    height: 60px;
    margin-left: -10px;
}

.content_text_navbar{
    justify-content: right;
}

.img_redes{
    width: 26px;
    height: 26px;
    margin-left: 1%;
}

.text_navbar{
    width: 67.5px;
    height: 17px;
    margin-right: 4%;
    margin-left: 7%;
    font-family: Gotham;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    text-align: right;
    color: #fff;
}

.text_navbar:hover{
    color: #B0B3AB;
}